<template>
  <div>
    <Loading v-show="$store.state.isLoading"/>
    <div v-show="!$store.state.isLoading" class="wrap">
      <div class="conTop">
        <van-row style="color:#333;font-weight: bold;">
          <van-col span="24">结清金额</van-col>
          <van-col span="24" style="font-size:0.5rem">{{cesuanAmount | formatMoney}}</van-col>
        </van-row>
        <van-row>
          <van-col span="12" style="color:#666;">结清日期:{{date}}</van-col>
          <van-col span="12" style="text-align:right;"><a style="color:#247FFF;" href="#">重新结清测算</a></van-col>
        </van-row>
      </div>
      <van-form @submit="onSubmit" input-align="right" error-message-align="right" label-width='4.5em'>
        <van-field
          readonly
          v-model="userName"
          name="userName"
          label="姓名"
          placeholder="姓名"
        />
        <van-field
          autosize
          readonly
          v-model="contractNum"
          name="contractNum"
          label="合同号"
        />
        <!-- <van-field
          v-if="columns.length !='1'"
          autosize
          readonly
          clickable
          name="contractNum"
          :value="contractNum"
          label="合同号"
          @click="showContractPicker = true"
          right-icon="arrow-down"
        />
        <van-popup v-model="showContractPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onContractConfirm"
            @cancel="showContractPicker = false"
          />
        </van-popup> -->
        <van-field
          readonly
          v-model="vin"
          name="vin"
          label="车架号"
          placeholder="车架号"
        />
        <van-field
          v-if="isReadonly"
          readonly
          v-model="settleType"
          name="settleType"
          label="结清方式"
        />
        <van-field
          v-if="!isReadonly"
          readonly
          clickable
          name="settleType"
          :value="settleType"
          label="结清方式"
          placeholder="请选择结清方式"
          @click="showCalculationPicker = true"
          right-icon="arrow-down"
          :rules="[{ required: true }]"
        />
        <van-popup v-model="showCalculationPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columnsCalculation"
            @confirm="onCalculationConfirm"
            @cancel="showCalculationPicker = false"
          />
        </van-popup>
        <van-field
          v-if="isReadonly && settleType=='银行卡扣款'"
          readonly
          v-model="payTime"
          name="payTime"
          label="扣款时间"
        />
        <van-field
          v-if="!isReadonly && settleType=='银行卡扣款'"
          readonly
          clickable
          name="payTime"
          :value="payTime"
          label="扣款时间"
          placeholder="请选择扣款时间"
          @click="showDeductionTimePicker = true"
          right-icon="arrow-down"
          :rules="[{ required: true }]"
        />
        <van-popup v-model="showDeductionTimePicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columnsDeductionTime"
            @confirm="onDeductionTimeConfirm"
            @cancel="showDeductionTimePicker = false"
          />
        </van-popup>
        <van-field
          v-if="settleType=='银行卡扣款'"
          readonly
          v-model="bankName"
          name="bankName"
          label="扣款银行"
        />
        <van-field
          class="dedCardNum"
          v-if="settleType=='银行卡扣款'"
          readonly
          v-model="accNumberTuoM"
          name="accNumberTuoM"
          label="扣款卡账号"
        >
        </van-field>
        <van-field
          v-if="settleType =='对公账户转账'"
          class="dedCardNum"
          readonly
          v-model="oulaBankname"
          name="oulaBankname"
          label="我司账户名称"
        />
        <van-field
          v-if="settleType =='对公账户转账'"
          readonly
          v-model="oulaNumber"
          name="oulaNumber"
          label="我司账号"
        />
        <van-field
          v-if="settleType =='对公账户转账'"
          class="dedCardNum"
          readonly
          v-model="oulaBankopen"
          name="oulaBankopen"
          label="我司账户开户行"
          rows="1"
          autosize
          type="textarea"
        />
        <!-- <van-field
          readonly
          v-model="date"
          name="date"
          label="结清日期"
        /> -->
        <van-field
          v-if="settleType =='对公账户转账'"
          :readonly="isReadonly"
          @focus="handdleFocus"
          rows="1"
          autosize
          type="textarea"
          v-model="accountName"
          name="accountName"
          :formatter="formatterAccountName"
          label="转账户名"
          maxlength="20"
          placeholder="请输入您的户名"
          :rules="accountRules"
        />
        <van-field
          v-if="settleType =='对公账户转账'"
          @focus="handdleFocus"
          v-model="amount"
          name="amount"
          :formatter="formatterAmount"
          label="转账金额"
          type="number"
          placeholder="请输入您的转账金额"
          :rules="amountRules"
        >
        <template #button v-if="settleType =='对公账户转账'">
            <span type="default" size="small">元</span>
          </template>
        </van-field>
        <van-field
          v-if="settleType =='对公账户转账'"
          v-model="upFile"
          name="upFile"  
          label="附件上传" 
          right-icon="arrow"
          :rules="[{ validator, message: '请选择附件' }]">
          <template #input v-if="settleType !=='银行卡扣款'">
            <span class="spanUpfile" v-if="upFile=='0'" type="default" size="small" @click="handdleUpfile">未上传</span>
            <span class="spanUpfile" v-else type="default" size="small" @click="handdleUpfile">已上传</span>
          </template>
        </van-field>
        <van-field
          v-if="isReadonlyShow"
          v-model="currentTime"
          type="currentTime"
          name="currentTime"
          label="申请时间"
          readonly
        />
        <div class="tips">
          <p>温馨提示：</p>
          <p>1、请于当天15:00前申请，15:00后申请则会顺延到次日。 </p>
          <!-- <p>2、月底最后一天不允许提前结清申请</p> -->
          <p>2、结清日期不能等于计划还款日</p>
          <p>3、申请进度可在【我的申请】中查看</p>
        </div>
        <van-col span="20" offset="2" style="position:fixed;bottom:10px;">
          <van-button :disabled="isReadonly" round block type="danger" native-type="submit">
          提交
          </van-button>
        </van-col>
      </van-form>
      <van-popup
        v-model="showVanpopup"
        :close-on-click-overlay="false"
        round
        position="bottom"
        :style="{ height: '310px' }"
      >
      <div class="tipTitile">对公账户转账须知</div>
        <div class="tipContent" style="margin-bottom:70px">
          <ul>
            <li>转账需在<span>15:00前到账</span>。转账成功后请将<span>转账截图上传至附件中</span>或发送至“<span>天津欧拉融资租赁</span>”微信公众号
            </li>
          </ul>
        </div>
        <van-col span="20" offset="2" style="position:fixed;bottom:10px;">
          <van-button round block type="danger" @click="confirmKnow">
          我知道了
          </van-button>
        </van-col>
      </van-popup>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import { dealAccountNoHide,formatMoney } from '@/lib/format';
  import { Dialog,Toast } from 'vant';
  import {settleApplicationDetail,settleApplication,upFileShow,upFileSave,getOpenId} from '@/server'
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  var dateArr;
  export default {
    components: {
      Loading
    },
    data() {
      return {
        contractNo:this.$store.state.calculationToApply.contractNo,//合同号
        showVanpopup:false,
        isReadonly:false,//已提交过后字段是否可编辑
        isReadonlyShow:false,//申请时间是否显示
        cardId:this.$store.state.cardId,
        accessToken:this.$store.state.accessToken,
        // openId:localStorage.getItem("openId") ||this.$store.state.openId,
        openId:getCookie('openId'),
        code: this.$route.query.code || '',
        backFlag: this.$store.state.backFlag,//是不是isReadonly返回1:false,0:true
        backFlagNum: this.$store.state.backFlagNum,//上传图片页返回 contractNum
        userName: '',
        contractNum: '',
        vin: '',
        settleType:'',//结清方式
        payTime:'',//扣款时间
        bankName:'',//扣款银行
        accNumberTuoM:'',//扣款卡账号脱敏
        accNumber:'',//扣款卡账号
        oulaBankname:'天津欧拉融资租赁有限公司',//我司账户名称
        oulaNumber:'13050166530800000457',//我司账号
        oulaNumberTuoM:'13050166530800000457',//我司账号脱敏
        oulaBankopen:'中国建设银行股份有限公司保定恒祥南大街支行',//我司账户开户行
        date: this.$store.state.calculationToApply.jqDate,//结清时间
        accountName:'',//转账户名
        cesuanAmount: this.$store.state.calculationToApply.moneyValue,//结清金额从上个页面带出
        amount:'',
        upFile:'',//附件是否上传
        fileList: [],
        currentTime:'',//申请时间
        showContractPicker: false,
        showCalculationPicker: false,
        showDeductionTimePicker:false,
        showCalendar: false,
        // columns: [],//合同列表
        columnsCalculation:['银行卡扣款','对公账户转账'],//结清方式
        columnsDeductionTime:['上午10:00','下午15:00'],//扣款时间
        dataList:[],//数据列表
        imgtype:'do_settle_base',//附件展示标志
        wxid:'',
        isYuQi:'1',//是否逾期，0:是，1否
        isShowConbutton:true,//是否显示日历的确认按钮
        accountRules:[{ required: true}],
        amountRules:[
          { required: true},
          {validator:this.validatorAmount1,message: '请填写正确的格式'},
          {validator:this.validatorAmount2,message: '请输入2位小数'}
        ]
      };
    }, 
  filters:{
    //格式化金额
    formatMoney(value, dot, digits){
      if(!value){
        return '--'
      }else{
        return "￥"+formatMoney(value, dot, digits)
      }
    },
    //格式化账号，银行卡账号脱敏
    formatAccountNo(value, dot, digits){
      return dealAccountNoHide(value, dot, digits)
    },
  },
  mounted(){
    let that = this;
    sessionStorage.setItem('key', 'early2');
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    	if(sessionStorage.getItem('key') == 'early2') {
        that.$router.push({ path: '/earlySettlementCalculation' });
      }
    }, false);
  },
  created(){
    this.oulaNumberTuoM = this.formatAccountNo(this.oulaNumberTuoM);
    //如果有openid
    // alert('openId:'+this.openId)
    if(this.openId !=='' && this.openId !==null && this.openId !== 'None'){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          this.$store.commit('openId', res.openid);
          setCookie('openId',res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          this.cardId = res.card_id;
          this.accessToken = res.access_token;
          this.openId = res.openid;
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/earlySettlementApplication' });
          }else{
            this.getFlagDetail();
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
        
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/earlySettlementApplication');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            this.cardId = res.card_id;
            this.accessToken = res.access_token;
            this.openId = res.openid;
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/earlySettlementApplication' });
            }else{
              this.getFlagDetail();
            }
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }
  },
  methods: {
    confirmKnow(){
      this.showVanpopup = false;
    },
    getFlagDetail(){
      console.log(this.contractNo)
      console.log(this.backFlag)
      var imgUrlList = this.$store.state.imgUrlList;
      if(this.backFlag =="1"){//backFlag为从上传图片页返回的时候所携带的合同号
        if(imgUrlList=='' || imgUrlList == [] || imgUrlList==null || imgUrlList==undefined){
          this.upFile = '0';//0代表未上传
        }else{
          this.upFile = imgUrlList.length;
        }
        var params = this.$store.state.writeData;//已经填写没有提交的数据
        console.log(params)
        this.contractNum = this.backFlagNum;
        this.userName = params.userName;
        this.vin = params.vin;
        this.accountName = params.accountName;
        this.settleType = params.settleType;
        this.amount = params.amount
        var myDate = new Date();
        this.currentTime = this.formatDate(myDate.getTime(),true);
        this.$store.commit('backFlagNum', '');
        this.$store.commit('backFlag', '');
      }else if(this.backFlag =="0"){//只读
        this.fileList = [];
        this.upFile = '0'
        this.$store.commit('imgUrlList', []);
        this.$store.commit('backFlagNum', '');
        this.$store.commit('backFlag', '');
        this.getDetail();
      }else{
        this.getDetail();
      }
    },
    //格式化账号，银行卡账号脱敏
    formatAccountNo(value, dot, digits){
      return dealAccountNoHide(value, dot, digits)
    },
    validator(val) {
      var imgurl = this.$store.state.imgUrlList;
      if(val=='0' || imgurl=='' || imgurl == [] || imgurl==null || imgurl==undefined){
        return false;
      }
    },
    validatorUser(val){
      Toast.fail({
        message: '您存在逾期租金，请及时还款',
        duration:'3000'
      });
    },
    validatorAmount1(val) {
      var i = val.substring(val.indexOf(".")+1).length;
      if(val.indexOf(".")== 0){//首位不能为小数点
        return false; 
      }else if(val!='0' && val.indexOf(".") == -1 && val.indexOf(0) == 0){//没有小数点且首位不能为0
        return false;
      }
    },
    validatorAmount2(val) {
      var i = val.substring(val.indexOf(".")+1).length;
      if(val.indexOf(".")>0 && i>2){//小数点后两位
        return false;
      }
    },
    formatterAccountName(value){
      //不允许填写空格、特殊字符等；
      // return value.replace(/\s+/g,'');
      return value.replace(/[\s@#￥\$%\^&\*]+/g,'');
    },
    formatterAmount(value) {
      // 输入内容只能为数字，不允许输入字母、空格、特殊符号等； 
      return value.replace(/[^0-9.]/g,"").replace(/(\.)(\d*)(\1*)/g,"$1$2");
      // return value.replace(/[^\d+(,\d\d\d)*.\d+$]/g,'')
    },
    formatDate(date, flag) {
      if (!date) return '--';
      if (flag) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return moment(date).format('YYYY-MM-DD');
      }
    },
    formatDate2(date) {
      if (!date) return '--';
      return moment(date).format('YYYY年MM月DD日');
    },
    getDetail(){
      this.isYuQi = '1';
      // this.columns=[];
      var myDate = new Date();
      this.currentTime = this.formatDate(myDate.getTime(),true);
      //信息返显
      const params = {
        access_token:this.accessToken,
        card_id:this.cardId
      }
      settleApplicationDetail(params).then(response=>{
        if(response.status == '0'){
          this.$store.commit('dataListTotal', response.detail_data); 
          let res = response.detail_data;
          var contractNoF = this.contractNo;
          var indexF = '';
          this.dataList = response.detail_data;
          res.map((item,i) => {
            if(item.con_no == contractNoF){
              indexF = i
            }
          });
        this.$store.commit('earlyDataList', res);
        var data = res[indexF];

        this.userName = data.cus_name;//姓名
        this.vin = data.vin;//车架号
        this.contractNum = data.con_no;//合同号
        this.bankName = data.bank_name;//扣款银行
        this.accNumber = data.acc_number;//扣款卡账号
        this.accNumberTuoM = this.formatAccountNo(data.acc_number);//扣款卡账号脱敏

        if(data.wx_code == '0'){//从来未提交的时候
          this.upFile = '0';//0代表未上传
          this.$store.commit('imgUrlList', []);
        }else if(data.wx_code == '5'){//已提交,不让再次提交的时候，信息返显且不可修改置灰状态
          this.showDataDisable(data);
          this.showImg();
        }else if(data.wx_code == '104'){//存在逾期
          Toast.fail({
            message: '您存在逾期租金，请及时还款',
            duration:'3000'
          });
          this.accountRules = [{validator:this.validatorUser}];
          this.amountRules = [{validator:this.validatorUser}];
          this.isYuQi = '0';
          this.upFile = '0';//0代表未上传
          this.isReadonly = true;
          this.isReadonlyShow = false;
        }else{
          this.upFile = '0';//0代表未上传
          Toast.fail({
            message: data.msg,
            duration:'3000'
          });
        }
        }else{
          Toast.fail({
            message: response.msgall,
            duration:'3000'
          });
        }
      })
    },
    //附件展示回显
    showImg(){
      const params = {
        wxid:this.wxid
      }
      upFileShow(params).then(res=>{//wx_code:0-存储成功,其他为错误 wx_code:1[没有读取到图片数据]"
        if(res.wx_code=='0'){
          this.fileList = res.imgurl;
          this.upFile = res.imgurl.length
        }else{
          this.fileList = [];
          this.upFile = '0';//0代表未上传
        }
      })
    },
    showDataDisable(data){//已提交过后不能再次提交且信息不能修改
      if(data.approval_status=='0' || data.approval_status=='3'){
        Toast.success({
          message: "审核中",
          duration:'3000'
        });
      }else if(data.approval_status=='1'){
        Toast.success({
          message: "审核通过",
          duration:'3000'
        });
      }
        this.wxid = data.wxid;
        this.accountName = data.account_name;
        this.amount = data.amount;
        this.currentTime = data.apply_time;
        this.date = data.settle_day;
        this.settleType = data.settle_type=='kakou'?'银行卡扣款':data.settle_type=='duigong'?'对公账户转账':'';//结清方式
        this.payTime = data.pay_time;//扣款时间
        this.oulaBankname = data.oula_bankname; //我司账户名称
        this.oulaNumber = data.oula_number; //我司账号
        this.oulaNumberTuoM = this.formatAccountNo(data.oula_number); //我司账号脱敏
        
        this.oulaBankopen = data.oula_bankopen;//我司账户开户行
        this.cesuanAmount = data.cesuan_amount;//测算金额
        this.isReadonly = true;
        this.isReadonlyShow = true;
        //data.approval_status,//0审核中/1审核通过/2退回/3退回再次申请
    },
    onCalculationConfirm(value) {
      if(value=="对公账户转账"){
        this.showVanpopup = true;
      }else{
        // console.log(this.$store.state.dataListTotal)
        // let data = this.$store.state.dataListTotal[0];
        //扣款银行和扣款账号赋值
        // this.bankName = data.bank_name;
        // this.accNumber = data.acc_number;
      }
      this.settleType = value;
      this.showCalculationPicker = false;
    },
    onDeductionTimeConfirm(value){
      if(value=="上午10:00"){
        Dialog.alert({
          message: '您好，您的提前结清申请已经受理。扣款<span style="color:#f00">'+this.formatDate2(this.date)+' 10：00</span>开始，预计用时2-3小时，请耐心等待，关注扣款结果',
          confirmButtonText:'我知道了'
        }).then(() => {
          this.payTime = '上午10:00';
        });
      }else if(value=="下午15:00"){
        Dialog.alert({
          message: '您好，您的提前结清申请已经受理。扣款<span style="color:#f00">'+this.formatDate2(this.date)+' 15：00</span>开始，预计用时2-3小时，请耐心等待，关注扣款结果',
          confirmButtonText:'我知道了'
        }).then(() => {
          this.payTime = '下午15:00';
        });
      }
      this.showDeductionTimePicker = false;
    },
    handdleUpfile(){
      console.log(this.$store.state.imgUrlList)
      const params = {
        userName:this.userName,//姓名
        vin:this.vin,//车架号
        accountName:this.accountName,//转账户名
        settleType:this.settleType,//结清方式
        amount:this.amount,//转账金额
      }
      if(this.isYuQi == '0'){
        Toast.fail({
          message: '您存在逾期租金，请及时还款',
          duration:'3000'
        });
      }else{
        this.$store.commit('writeData', params);
        this.$store.commit('fileList', this.fileList);
        this.$router.push({ 
          path: '/upFile',
          query: { 
            conNo: this.contractNum,
            isReadonly:this.isReadonly,
            imgtype:this.imgtype
          }
        });
      }
      
    },
    onSubmit(values) {
      
      var imgurl = this.$store.state.imgUrlList;
      // #card_id, access_token, con_no, settle_day, vin, account_name, amount, apply_time 
      var myDate = new Date();
      this.currentTime = this.formatDate(myDate.getTime(),true);
      const params = {
        access_token:this.accessToken,
        card_id:this.cardId,
        con_no:values.contractNum,//合同号
        settle_day:this.date,//结清日期
        vin:values.vin,
        account_name:values.accountName,//转账户名
        amount:values.amount,//转账金额
        cesuan_amount:this.cesuanAmount,//结清金额
        settle_type:values.settleType=='银行卡扣款'?'kakou':values.settleType=='对公账户转账'?'duigong':'',//结清方式
        pay_time:values.payTime,//扣款时间
        bank_name:values.bankName,//扣款银行
        acc_number:this.accNumber,//扣款卡账号
        oula_bankname:values.oulaBankname, //我司账户名称
        oula_number:this.oulaNumber, //我司账号
        oula_bankopen:values.oulaBankopen,//我司账户开户行
        apply_time:this.currentTime,
        imgurl:imgurl
      }
      Dialog.confirm({
        message: '申请后无法撤销\n请确认是否提交',
      })
      .then(() => {
        if(values.settleType !=='银行卡扣款'){
          if(imgurl.length =='0' || imgurl==null || imgurl == undefined){
            this.upFile = '0';
          }else{
            settleApplication(params).then(res=>{
              if(res.wx_code=='0'){
                Toast({
                  message: '提交成功！',
                  icon: require('@/assets/img/bindSuccess.png'),
                  duration:'3000'
                });
                this.$store.commit('imgUrlList', []);
                this.$store.commit('backFlag', '');
                this.$store.commit('writeData', {});
                this.getDetail();
              }else{
                this.$store.commit('imgUrlList', []);
                this.$store.commit('backFlag', '');
                this.$store.commit('writeData', {});
                Toast({
                  message: res.msg,
                  icon: require('@/assets/img/bindError.png'),
                  duration:'3000'
                });
              }
            })
          }
        }else{
          settleApplication(params).then(res=>{
            if(res.wx_code=='0'){
              Toast({
                message: '提交成功！',
                icon: require('@/assets/img/bindSuccess.png'),
                duration:'3000'
              });
              this.$store.commit('imgUrlList', []);
              this.$store.commit('backFlag', '');
              this.$store.commit('writeData', {});
              this.getDetail();
            }else{
              this.$store.commit('imgUrlList', []);
              this.$store.commit('backFlag', '');
              this.$store.commit('writeData', {});
              if(res.wx_code=='4L10' || res.wx_code=='4L15'){
                Toast({
                  message: '当前时间临近或超过扣款时间，无法提交',
                  icon: require('@/assets/img/bindError.png'),
                  duration:'3000'
                });
              }else{
                Toast({
                  message: res.msg,
                  icon: require('@/assets/img/bindError.png'),
                  duration:'3000'
                });
              } 
            }
          })
        }
      })
      .catch(() => {
        // on cancel
      });
      
      
    },
    handdleFocus(){
      if (/Android/gi.test(navigator.userAgent)) {//浏览器属性为安卓时生效
        window.addEventListener('resize', function () {//屏幕大小发生变化（调出虚拟键盘时触发）
          if (document.activeElement.tagName == 'INPUT' || document.activeElement.tagName == 'TEXTAREA') {
            window.setTimeout(function () {
              document.activeElement.scrollIntoViewIfNeeded();//移动页面使输入框置于可视区域
              document.activeElement.scrollIntoView();
            }, 300); 
          }
        })
      }
    }
  },
};
</script>
<style scoped>
.wrap{
    padding: 10px 0px 20px 0px;
    /* height: 100vh; */
    /* background-color: #fff; */
    background-color: #F8F8F8;
  }
.van-button--default{
  border: 0px;
}
.tips{
  color:#808080;
  font-size:13px;
  margin-top: 20px;
  margin-bottom: 60px;
}
.tips p{
    margin: 6px 15px;
}
.conTop{
  background-color: #fff;
  margin: 15px;
  padding: 15px;
  line-height: 35px;
  border-radius: 5px;
  font-size: 0.28rem;
}
.tipTitile{
  color: #1A1A1A;
  font-size: 0.35rem;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #eee;
  height: 50px;
  line-height: 50px;
  /* margin-top: 10px; */
}
.tipContent{
  background-color: #F5F5F5;
  margin: 20px 20px;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 0.3rem;
}
.tipContent ul{
  margin-left: 23px;
}
.tipContent li{
  list-style-type: disc;
  color: #333;
  line-height: 0.55rem;
  margin: 15px 0px;
}
.tipContent li span{
  color: #E50012;
}
</style>
<style>
textarea{
  overflow:hidden;
}
.wrap .van-field__control{
  color: #666666 !important;
}
/* #app{
  background: #fff !important;
} */
.spanUpfile{
  width: 100%;
}
.dedCardNum .van-field__label{
  width: 7em !important;
}
/* .comBankName .van-field__label{
  width: 7em !important;
} */
</style>